import { useLocation } from 'react-router-dom'

/**
 * check if path routing is the same
 */
export const useIsPathPage = (path: string): boolean => {
	const location = useLocation()
	const reg = new RegExp(`^${path}`)
	return reg.test(location.pathname)
}
