import { Button as AntButton, ButtonProps } from 'antd'
import { lighten } from 'polished'
import { FC } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import styled from 'styled-components'
import { FontSizeResponsive } from 'styles/mixins/FontSizeResponsive'

const ButtonStyled = styled(AntButton)`
	display: inline-block;
	height: auto;
	cursor: pointer;
	transition: ease all 0.3s;
	font-weight: ${({ theme }) => theme.weight.extraBold};
	// default medium size
	padding: 15px 40px;
	${FontSizeResponsive({ size: 'medium' })};
	line-height: ${({ theme }) => theme.lineHeight.big};
	letter-spacing: 1px;
	border-radius: 30px;
	// default primary type
	border: 2px solid ${({ theme }) => theme.color.blue};
	background: ${({ theme }) => theme.color.blue};
	&.ant-btn-background-ghost {
		color: ${({ theme }) => theme.color.blue};
		background: transparent;
	}

	&.ant-btn-default {
		border: 2px solid ${({ theme }) => theme.color.gray};
		color: ${({ theme }) => theme.color.gray};
		background: ${({ theme }) => theme.color.white};

		&.ant-btn-background-ghost {
			background: transparent;
			border: 2px solid ${({ theme }) => theme.color.white};
			color: ${({ theme }) => theme.color.white};
		}
	}
	&.btn-type-pay {
		border: 2px solid ${({ theme }) => theme.color.green};
		color: ${({ theme }) => theme.color.white};
		background: ${({ theme }) => theme.color.green};

		&.ant-btn-background-ghost {
			background: transparent;
			border: 2px solid ${({ theme }) => theme.color.green};
			color: ${({ theme }) => theme.color.green};
		}
	}
	&.ant-btn-small {
		padding: 12px 33px;
		${FontSizeResponsive({ size: 'small' })};
		border-radius: 25px;
	}
	&.ant-btn-lg {
		padding: 20px 50px;
		${FontSizeResponsive({ size: 'big' })};
		border-radius: 40px;
	}

	&.as-button {
		&.ant-btn-small {
			padding: 12px 33px;
		}
	}

	&:hover,
	&.active {
		background: ${({ theme }) => lighten(0.05, theme.color.blue)};
		border-color: ${({ theme }) => lighten(0.05, theme.color.blue)};

		&.ant-btn-background-ghost {
			color: ${({ theme }) => theme.color.blue};
			border-color: ${({ theme }) => theme.color.blue};
			background: transparent;
		}
		&.ant-btn-default {
			color: ${({ theme }) => theme.color.gray};
			background: ${({ theme }) => theme.color.white};
			border-color: ${({ theme }) => theme.color.gray};
			&.ant-btn-background-ghost {
				color: ${({ theme }) => theme.color.white};
				border-color: ${({ theme }) => theme.color.white};
				background: transparent;
			}
		}
		&.btn-type-pay {
			color: ${({ theme }) => theme.color.white};
			background: ${({ theme }) => lighten(0.05, theme.color.green)};
			border-color: ${({ theme }) => lighten(0.05, theme.color.green)};
			&.ant-btn-background-ghost {
				color: ${({ theme }) => theme.color.green};
				border-color: ${({ theme }) => theme.color.green};
				background: transparent;
			}
		}
	}
`
interface IProps {
	btnType?: 'pay'
}
export const InternalButtonLink: FC<ButtonProps & NavLinkProps & IProps> = ({
	children,
	type = 'primary',
	size,
	ghost,
	btnType,
	...props
}) => {
	const className = `ant-btn-${size} ant-btn-${type} ${ghost ? 'ant-btn-background-ghost' : ''} ${btnType ? `btn-type-${btnType}` : ''}`
	return (
		<ButtonStyled as={NavLink} className={className} {...props}>
			{children}
		</ButtonStyled>
	)
}
export const ExternalButtonLink: FC<ButtonProps & IProps> = ({ children, type = 'primary', size, ghost, btnType, ...props }) => {
	const className = `ant-btn-${size} ant-btn-${type} ${ghost ? 'ant-btn-background-ghost' : ''} ${btnType ? `btn-type-${btnType}` : ''}`
	return (
		<ButtonStyled as={'a'} className={className} {...props}>
			{children}
		</ButtonStyled>
	)
}
export const Button: FC<ButtonProps & IProps> = ({ children, type = 'primary', size, ghost, btnType, ...props }) => {
	const className = `as-button ant-btn-${size} ant-btn-${type} ${ghost ? 'ant-btn-background-ghost' : ''} ${
		btnType ? `btn-type-${btnType}` : ''
	}`
	return (
		<ButtonStyled as={'button'} className={className} {...props}>
			{children}
		</ButtonStyled>
	)
}
