import { Logo } from 'assets/icon'
import { PageNavigation } from 'components/pageNavigation/PageNavigation'
import { FC } from 'react'
import { PATH } from 'Routes'
import styled, { css } from 'styled-components'
import { MobileView } from 'styles/mixins/MobileView'
import { ExternalLink } from 'ui/Link'

import { PageContent } from 'ui/PageContent'

export const HEADER_HEIGHT = '100px'
export const HEADER_HEIGHT_XS = '70px'

const LogoStyled = styled(Logo)`
	font-size: 8.1em;
`

const Header = styled.div`
	height: ${HEADER_HEIGHT};
	display: flex;
	justify-content: space-between;
	align-items: center;

	${MobileView(
		css`
			height: ${HEADER_HEIGHT_XS};
		`,
		{ screen: 'xs' },
	)}
`

const PageHeader: FC = () => {
	return (
		<PageContent>
			<Header>
				<h1>
					<ExternalLink href={PATH.HOME} title="Bitclear">
						<LogoStyled />
					</ExternalLink>
				</h1>
				<PageNavigation />
			</Header>
		</PageContent>
	)
}

export default PageHeader
