import { FC } from 'react'
import styled, { css } from 'styled-components'
import { MobileView } from 'styles/mixins/MobileView'

interface IBg {
	background?: string
}
const Bg = styled.div<IBg>`
	display: block;
	background: ${(props) => props.background};
	width: 100%;
`

interface IContentBox {
	fullWidthContent?: boolean
}
const ContentBox = styled.div<IContentBox>`
	width: 100%;
	position: relative;
	margin: 0 auto;
	max-width: ${(props) => (props.fullWidthContent ? 'none' : `calc(${props.theme.screen.hq} + 2%)`)};
	padding: 0 1%;

	${MobileView(css`
		padding: 0 5%;
	`)}
`

type IProps = IBg & IContentBox
export const PageContent: FC<IProps> = ({ children, background, fullWidthContent }) => (
	<Bg background={background}>
		<ContentBox fullWidthContent={fullWidthContent}>{children}</ContentBox>
	</Bg>
)
