import { MenuOutlined } from '@ant-design/icons'
import { Menu, Modal } from 'antd'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { FC, ReactElement, useState } from 'react'
import styled, { css } from 'styled-components'
import { MobileView } from 'styles/mixins/MobileView'

const Wrapper = styled.div`
	display: none;
	${MobileView(
		css`
			display: inline-block !important;
		`,
		{ screen: 'xs' },
	)}

	svg {
		font-size: 2em;
		color: ${({ theme }) => theme.color.blue};
		cursor: pointer;
	}
`
const ModalStyled = styled(Modal)`
	top: 0;

	.ant-modal-body {
		padding-top: 10px;
	}
	.ant-modal-close {
		top: 5px;
		right: 2px;

		svg {
			font-size: 2em;
			color: ${({ theme }) => theme.color.blue};
		}
	}
`
const MenuStyled = styled(Menu)`
	font-size: ${({ theme }) => theme.size.small}!important;
	border: none !important;

	& > .ant-menu-item,
	& > .product-submenu {
		margin-bottom: 30px !important;
	}
	.product-submenu {
		border-bottom: 1px solid ${({ theme }) => theme.color.grayWhite};

		.ant-menu-submenu-title svg {
			display: none;
		}
		.ant-menu {
			font-size: ${({ theme }) => theme.size.small};
			background: transparent;

			.ant-menu-item {
				padding-left: 24px !important;
				margin-bottom: 30px;

				a {
					display: flex;
					justify-content: flex-start;
					align-items: center;

					.image {
						display: inherit;
						width: 100px;

						svg {
							font-size: 5em;
						}
					}
				}
			}
		}
	}
	.product-submenu-small {
		display: none;
	}
	.btn-inside {
		height: auto;
		text-align: center;

		.ant-btn-small {
			min-width: 125px;
		}
	}
`

export const MenuNavMobile: FC<{ items: ReactElement[] }> = ({ items }) => {
	const [showModal, setShowModal] = useState(false)
	const closeModal = () => setShowModal(false)
	const openModal = () => setShowModal(true)
	const onItemClick: MenuClickEventHandler = () => {
		closeModal()
	}

	return (
		<Wrapper>
			<MenuOutlined onClick={openModal} />
			<ModalStyled visible={showModal} onCancel={closeModal} title={<>&nbsp;</>} footer={null}>
				<MenuStyled
					mode={'inline'}
					selectable={false}
					overflowedIndicator={false}
					forceSubMenuRender
					openKeys={['product']}
					onClick={onItemClick}>
					{items}
				</MenuStyled>
			</ModalStyled>
		</Wrapper>
	)
}
