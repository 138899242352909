import { MenuOutlined } from '@ant-design/icons'
import { Menu } from 'antd'
import { FC, ReactElement } from 'react'
import styled, { createGlobalStyle, css } from 'styled-components'
import { FontSizeResponsive } from 'styles/mixins/FontSizeResponsive'
import { DesktopView, MobileView } from 'styles/mixins/MobileView'
import { IThemeStyles } from 'styles/themeStyles'

const logoWidth = '200px'
const Wrapper = styled.div`
	width: calc(100% - ${logoWidth});
`
const MenuStyled = styled(Menu)`
	justify-content: flex-end;
	border: none;

	${MobileView(
		css`
			display: none !important;
		`,
		{ screen: 'xs' },
	)}

	.ant-menu-item:after,
	.ant-menu-submenu:after {
		// remove ant underline
		display: none;
	}

	li.btn-inside {
		.ant-btn-default {
			&,
			&:hover {
				color: ${({ theme }) => theme.color.gray};
			}
		}

		&:after {
			display: none;
		}
	}
`
const GlobalStyles = createGlobalStyle<{ theme: IThemeStyles }>`
	.ant-menu {
    ${FontSizeResponsive({ size: 'small' })};
		font-weight: ${({ theme }) => theme.weight.bold};
	}
	
  .ant-menu-submenu-popup li.btn-inside {
		&, .ant-menu-submenu-title {
			height: auto;
			line-height: 4em;
		}
		// menu.submenu base item styles
    .ant-menu-submenu-title {
			padding-right: 0;
		}

    .ant-btn-small {
      display: block;
      line-height: ${({ theme }) => theme.lineHeight.medium};
      text-align: center;
      min-width: 130px;
    }
  }

	// hide arrow for submenu on small screen (hidden by hamburger)
	.ant-menu-submenu-arrow {
		display: none!important;
	}
	
  // hamburger menu
  .ant-menu-overflow-item-rest {
    font-size: 2em;

    svg {
      font-size: 1.5em;
    }
  }

	// styles for small product submenu
	.product-navigation-popup-small{
    ul.ant-menu{
    	${FontSizeResponsive({ size: 'normal' })};
			padding: 10px 0;
			border-radius: 20px;
			
      li.ant-menu-item {
				height: auto;
				
				a {
					padding: 10px 10px 0 10px;
					border-radius: 10px;
					display: block;
					transition: border ease 0.5s;
					border: solid 1px transparent;
					
					&:hover {
						border: solid 1px ${({ theme }) => theme.color.blue};
					}
				}
				svg {
					font-size: 4em;
					line-height: 4em;
				}
      }
			
		}
	}
	
  // styles for product submenu
  .product-navigation-popup {
    max-width: 99vw;
    width: auto;
		height: auto;

    ul.ant-menu {
      ${FontSizeResponsive({ size: 'normal' })};
			font-weight: ${({ theme }) => theme.weight.extraBold};
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      padding: 40px;
      border-radius: 20px;

			svg{
				font-size: 4em;
				line-height: 4em;
				margin-bottom: 20px;
			}
			
      li.ant-menu-item {
        width: 325px;
        padding: 18px 0 0 0;
				margin: 0;
        height: auto;
				line-height: ${({ theme }) => theme.lineHeight.small};

        &:first-child {
          border-bottom: 1px solid ${({ theme }) => theme.color.grayLight};
          padding: 0 0 18px 0;
        }
      }

      ${DesktopView(
				css`
					flex-direction: row;

					li.ant-menu-item {
						border: none;
						padding: 0 0 0 18px;

						&:first-child {
							border: none;
							border-right: 1px solid ${({ theme }) => theme.color.grayLight};
							padding: 0 18px 0 0;
						}
					}
				`,
				{ screen: 'md' },
			)}
    }
  }
`

export const MenuNav: FC<{ items: ReactElement[] }> = ({ items }) => {
	return (
		<Wrapper>
			<GlobalStyles />
			<MenuStyled mode={'horizontal'} selectable={false} overflowedIndicator={<MenuOutlined />} forceSubMenuRender>
				{items}
			</MenuStyled>
		</Wrapper>
	)
}
