import { Menu } from 'antd'
import { Arrow, Bfx, Bpay } from 'assets/icon'
import { isFxLogged } from 'commons/isFxLogged'
import { MenuNav } from 'components/pageNavigation/MenuNav'
import { MenuNavMobile } from 'components/pageNavigation/MenuNavMobile'
import { FxInBtn } from 'components/pages/fx/FxInBtn'
import { FX_LOGIN, KYC_REGISTER, KYC_REGISTER_PAY, PAY_LOGIN } from 'config/exLinks'
import { useIsPathPage } from 'hooks/useIsPathPage'
import { FC, ReactElement, ReactNode } from 'react'
import { CONTACT_PARAMS, PATH } from 'Routes'
import styled from 'styled-components'
import { themeStyles } from 'styles/themeStyles'
import { Button, ExternalButtonLink } from 'ui/Button'
import { InternalLink } from 'ui/Link'

const MenuArrow = styled(Arrow)`
	transform: rotate(-90deg);
	font-size: 0.9em;
	margin: 0 0 -2px 4px;
`

const productMenu: ReactElement = (
	<Menu.SubMenu
		className={'product-submenu'}
		key={'product'}
		popupClassName={'product-navigation-popup'}
		title={
			<>
				Products <MenuArrow />
			</>
		}>
		<Menu.Item key={'exchange'}>
			<InternalLink to={PATH.FX} underline={false}>
				<span className={'image'}>
					<Bfx />
				</span>
				<div style={{ color: themeStyles.color.orange }}>Crypto Exchange</div>
			</InternalLink>
		</Menu.Item>
		<Menu.Item key={'payment-gate'}>
			<InternalLink to={PATH.PAY} underline={false}>
				<span className={'image'}>
					<Bpay />
				</span>
				<div style={{ color: themeStyles.color.green }}>Payment Gate</div>
			</InternalLink>
		</Menu.Item>
	</Menu.SubMenu>
)
const productMenuSmall = (title: ReactNode, fxLink: string, payLink: string, key: string): ReactElement => (
	<Menu.SubMenu key={key} title={title} popupClassName={'product-navigation-popup-small'} className={'product-submenu-small btn-inside'}>
		<Menu.Item key={'exchange'}>
			<a href={fxLink}>
				<span className={'image'}>
					<Bfx />
				</span>
			</a>
		</Menu.Item>
		<Menu.Item key={'payment-gate'}>
			<a href={payLink}>
				<span className={'image'}>
					<Bpay />
				</span>
			</a>
		</Menu.Item>
	</Menu.SubMenu>
)

const baseMenu: ReactElement[] = [
	productMenu,
	<Menu.Item key={'about-us'}>
		<InternalLink to={PATH.ABOUT_US}>About us</InternalLink>
	</Menu.Item>,
]
const contactMenu = (path: string): ReactElement => (
	<Menu.Item key={'contact'}>
		<InternalLink to={`${PATH.CONTACT}/${path}`}>Contact</InternalLink>
	</Menu.Item>
)
const payMenu: ReactElement[] = [
	<Menu.Item key={'sign_up'} className={'btn-inside'}>
		<ExternalButtonLink href={KYC_REGISTER_PAY} type={'primary'} ghost target="_self" size={'small'} btnType={'pay'}>
			REGISTER
		</ExternalButtonLink>
	</Menu.Item>,
	<Menu.Item key={'login'} className={'btn-inside'}>
		<ExternalButtonLink href={PAY_LOGIN} type={'primary'} ghost target="_self" size={'small'} btnType={'pay'}>
			LOG IN
		</ExternalButtonLink>
	</Menu.Item>,
]

const getFxMenu = (): ReactElement => {
	return (
		<>
			{!isFxLogged() ? (
				<Menu.Item key={'fx-signin'} className={'btn-inside'}>
					<FxInBtn isGhost showBtn={'sign'} />
				</Menu.Item>
			) : null}
			<Menu.Item key={'fx-login'} className={'btn-inside'}>
				<FxInBtn isGhost showBtn={'login'} showDashboard />
			</Menu.Item>
		</>
	)
}

const productBtn = (): ReactElement => {
	return (
		<>
			<Menu.Item key={'register'}>
				<ExternalButtonLink
					href={KYC_REGISTER}
					type={'primary'}
					ghost
					style={{ color: themeStyles.color.blue }}
					size={'small'}
					target="_self">
					REGISTER
				</ExternalButtonLink>
			</Menu.Item>
			{productMenuSmall(
				<Button ghost style={{ color: themeStyles.color.blue }} size={'small'}>
					LOG IN
				</Button>,
				FX_LOGIN,
				PAY_LOGIN,
				'login',
			)}
		</>
	)
}

export const PageNavigation: FC = () => {
	const isPayPage = useIsPathPage(PATH.PAY)
	const isFxPage = useIsPathPage(PATH.FX)
	let menu: ReactElement[] = []

	if (isPayPage) {
		menu = [...baseMenu, contactMenu(CONTACT_PARAMS.PAY), ...payMenu]
	} else if (isFxPage) {
		menu = [...baseMenu, contactMenu(CONTACT_PARAMS.FX), getFxMenu()]
	} else {
		menu = [...baseMenu, contactMenu(''), productBtn()]
	}

	return (
		<>
			<MenuNav items={menu} />
			<MenuNavMobile items={menu} />
		</>
	)
}
