import { isFxLogged } from 'commons/isFxLogged'
import { FX_LOGIN, FX_MAIN, KYC_REGISTER_FX } from 'config/exLinks'
import { FC } from 'react'
import styled from 'styled-components'
import { themeStyles } from 'styles/themeStyles'
import { ExternalButtonLink } from 'ui/Button'

const Wrapper = styled.div``

interface IProps {
	showBtn: 'login' | 'sign'
	showDashboard?: boolean
	isGhost?: boolean
}
export const FxInBtn: FC<IProps> = ({ showDashboard, isGhost, showBtn }) => (
	<Wrapper>
		{isFxLogged() ? (
			showDashboard ? (
				<ExternalButtonLink
					href={FX_MAIN}
					type={'primary'}
					ghost={isGhost}
					style={isGhost ? { color: themeStyles.color.blue } : undefined}
					size={'small'}
					target="_self">
					DASHBOARD
				</ExternalButtonLink>
			) : null
		) : (
			<>
				{showBtn === 'sign' ? (
					<ExternalButtonLink
						href={KYC_REGISTER_FX}
						type={'primary'}
						ghost={isGhost}
						style={isGhost ? { color: themeStyles.color.blue } : undefined}
						size={'small'}
						target="_self">
						REGISTER
					</ExternalButtonLink>
				) : null}
				{showBtn === 'login' ? (
					<ExternalButtonLink
						href={FX_LOGIN}
						type={'primary'}
						ghost={isGhost}
						style={isGhost ? { color: themeStyles.color.blue } : undefined}
						size={'small'}
						target="_self">
						LOG IN
					</ExternalButtonLink>
				) : null}
			</>
		)}
	</Wrapper>
)
